import { FilterFormSubmitButton, FilterFormTexts } from '../model/form-model';

export const defaultLabels: FilterFormTexts = {
  action: 'Mám záujem o',
  subcategory: 'Typ nehnuteľnosti',
  city: 'Lokalita',
  priceRange: 'Cena',
  condition: 'Stav nehnuteľnosti',
  keywords: 'Kľúčové slová',
  sortBy: 'Zoradiť podľa',
  areaRange: 'Rozloha',
};

export const defaultPlaceholders: FilterFormTexts = {
  action: 'Všetky možnosti',
  subcategory: 'Všetky možnosti',
  city: 'Všetky možnosti',
  condition: 'Všetky možnosti',
  keywords: 'garáž, balkón...',
  sortBy: 'Všetky možnosti',
};

export const defaultSubmitButton: FilterFormSubmitButton = {
  text: 'Hľadať nehnuteľnosť',
  loadingText: 'Hľadám nehnuteľnosť',
  colorScheme: 'black',
};

export const ACTIVE_PROPERTY_STATUS_FILTER = {
  or: [{ realsoftId: { eq: 1 } }, { realsoftId: { eq: 3 } }, { realsoftId: { eq: 2 } }, { realsoftId: { eq: 7 } }],
};
export const REALIZED_PROPERTY_STATUS_FILTER = {
  or: [
    { realsoftId: { eq: 9 } },
    { realsoftId: { eq: 4 } },
    { realsoftId: { eq: 8 } },
  ],
};


export const ACTIVE_PROPERTY_STATUS_SLUG = 'active'
export const REALIZED_PROPERTY_STATUS_SLUG = 'realized'