import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface PropertyTypeButtonProps extends BoxProps {
  label: string;
  icon: ReactNode;
  active?: boolean;
}

export const PropertyTypeButton = (props: PropertyTypeButtonProps) => {
  const { icon, label, active, ...restOfProps } = props;
  return (
    <Box
      {...restOfProps}
      _hover={{
        transform: 'scale(1.015)',
        transition: 'all ease-in-out 300ms',
      }}
    >
      <Flex gridGap={2} alignItems="center" cursor={'pointer'}>
        <Flex h={12} alignItems="center">
          {icon}
        </Flex>
        <Box
          fontWeight={active ? 700 : 500}
          fontSize={{ base: 'sm', lg: 'md' }}
          color={active ? 'primary.500' : 'black'}
        >
          {label}
        </Box>
      </Flex>
    </Box>
  );
};
