export const getCloudinaryWatermarkUrl = (
  url: string,
  watermarkType = 'standard',
  phone?: string
) => {
  if (!url) {
    return;
  }
  const cloudinaryUrl = 'https://res.cloudinary.com/mmntm-digital/image/upload';
  const watermarkUrl = 'w_1920,h_1080/w_1920,h_1080,l_watermark-';
  const urlItems = url.split('/');
  const imageName = urlItems[urlItems.length - 1];

  let finalUrl;

  if (watermarkType === 'tour-blank') {
    const phoneOverlay = `l_text:Arial_68_bold:${
      phone ? phone.split(' ').join('%20') : '+421 948 504 607'
    },co_white,g_north_west,x_660,y_684`;
    finalUrl = `${cloudinaryUrl}/${watermarkUrl}${watermarkType}/${phoneOverlay}/${imageName}`;
  } else {
    finalUrl = `${cloudinaryUrl}/${watermarkUrl}${watermarkType}/${imageName}`;
  }

  return finalUrl;
};

// https://res.cloudinary.com/mmntm-digital/image/upload/w_1920,h_1080/w_1920,h_1080,l_watermark-tour-blank/l_text:Arial_68_bold:+421%20903%20370%20897,co_white,g_north_west,x_660,y_684/2_izbovy_byt_silvanova_pezinok_brickreality_x_c88b62512d.jpg
