import { SvgIcon } from '@brick/ui-components';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import blockIcon from '../assets/block.svg';
import garageIcon from '../assets/garage.svg';
import houseIcon from '../assets/house.svg';
import landIcon from '../assets/land.svg';
import officeIcon from '../assets/office.svg';
import { FilterFormValues } from '../model/form-model';
import { PropertyTypeButton } from './PropertyTypeButton';

export interface PropertyTypeButtonsProps extends BoxProps {
  onSubmitHandler: (data: FilterFormValues) => void;
}

const propertyTypeButtons = [
  {
    label: 'Byty',
    id: '4',
    icon: (
      <Box pb={2} mr={-1}>
        <SvgIcon src={blockIcon} size={52} />
      </Box>
    ),
  },
  {
    label: 'Rodinné domy',
    id: '6',
    icon: <SvgIcon src={houseIcon} size={44} />,
  },
  {
    label: 'Pozemky',
    id: '3',
    icon: (
      <Box pb={2} ml={-3}>
        <SvgIcon src={landIcon} size={54} />
      </Box>
    ),
  },
  {
    label: 'Garáže',
    id: '11',
    icon: <SvgIcon src={garageIcon} size={48} />,
  },
  {
    label: 'Kancelárie',
    id: '9',
    icon: <SvgIcon src={officeIcon} size={52} />,
  },
];

export const PropertyTypeButtons = (props: PropertyTypeButtonsProps) => {
  const { onSubmitHandler, ...restOfProps } = props;
  const { setValue, handleSubmit } = useFormContext();
  const router = useRouter();

  return (
    <Box {...restOfProps}>
      <Flex justifyContent={{ md: 'space-between', lg: 'space-evenly' }}>
        {propertyTypeButtons.map((b) => (
          <PropertyTypeButton
            {...b}
            key={b.label}
            active={router.query['category'] === b.id}
            onClick={() => {
              setValue('subcategory', '');
              setValue('category', b.id);
              handleSubmit(onSubmitHandler)();
            }}
          />
        ))}
      </Flex>
    </Box>
  );
};
