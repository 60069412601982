import { ACTIVE_PROPERTY_STATUS_FILTER } from '../const/defaults';

export interface GetFilterOptionsFilterProps {
  useOnlyRelevantOptions?: boolean;
  includeOnlyActiveProperties?: boolean;
}

export const getFilterOptionsQuery = (
  props: GetFilterOptionsFilterProps = {
    useOnlyRelevantOptions: true,
    includeOnlyActiveProperties: false,
  }
) => {
  let query;

  // use only relevant options - all including non active projects
  if (props.useOnlyRelevantOptions) {
    query = { projects: { id: { notNull: true } } };
  }

  // use only relevant options - for only active projects
  if (props.includeOnlyActiveProperties) {
    query.projects = {
      status: ACTIVE_PROPERTY_STATUS_FILTER,
    };
  }

  return {
    actionFilters: query,
    conditionFilters: query,
    subcategoryFilters: query,
    cityFilters: query,
  };
};
