import {
  FormControlGroup,
  FormControlGroupSelect,
  RangeSlider,
} from '@brick/forms';
import { Button, Container, FetchingError } from '@brick/ui-components';
import { removeEmptyStringValues } from '@brick/utils';
import { Box, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { menuRoutes } from 'apps/client/lib/routes/routes';
import { useRouter } from 'next/router';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { ClearFilterButton } from './components/ClearFilterButton';
import { PropertyTypeButtons } from './components/PropertyTypeButtons';
import {
  defaultLabels,
  defaultPlaceholders,
  defaultSubmitButton,
} from './const/defaults';
import { useFilterOptions } from './hooks/useFilterOptions';
import { FilterFormProps, FilterFormValues } from './model/form-model';

export const PropertyFilter = (props: FilterFormProps) => {
  const {
    placeholders = defaultPlaceholders,
    labels = defaultLabels,
    submitButton = defaultSubmitButton,
    showAllFields = true,
    isLoading,
  } = props;

  const router = useRouter();
  const methods = useForm<FilterFormValues>({ defaultValues: router.query });

  const onSubmit = async (data: FilterFormValues) => {
    router.push(
      {
        pathname: menuRoutes.properties.href,
        query: removeEmptyStringValues(data),
      },
      undefined,
      { shallow: true }
    );
  };

  const filterOptions = useFilterOptions();

  if (!filterOptions) {
    return <FetchingError />;
  }
  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <Container mb={-10}>
          <Stack
            spacing={{ base: 4, md: 4 }}
            mt={{ base: -10, md: 0 }}
            mx={{ base: -4, md: 0 }}
          >
            <Box p={4} bg="grey.100">
              <SimpleGrid
                columns={{ sm: 2, lg: 4 }}
                columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
                rowGap={{ base: 4 }}
              >
                <FormControlGroupSelect
                  options={filterOptions.action}
                  name="action"
                  label={labels.action}
                  placeholder={placeholders.action}
                  selectProps={
                    {
                      // defaultValue: filterOptions.action[0].value,
                    }
                  }
                />
                <FormControlGroupSelect
                  options={filterOptions.subcategory}
                  name="subcategory"
                  label={labels.subcategory}
                  placeholder={placeholders.subcategory}
                />
                <FormControlGroupSelect
                  options={filterOptions.city}
                  name="city"
                  label={labels.city}
                  placeholder={placeholders.city}
                />
                <RangeSlider
                  label={labels.priceRange}
                  range={filterOptions.priceRange}
                  name={'priceRange'}
                  unit={'€'}
                />
              </SimpleGrid>
              <SimpleGrid
                display={showAllFields ? 'grid' : 'none'}
                columns={{ sm: 2, lg: 4 }}
                columnGap={{ base: 4, md: 5, lg: 5, xl: 7 }}
                rowGap={{ base: 4 }}
              >
                <FormControlGroupSelect
                  options={filterOptions.condition}
                  name="condition"
                  label={labels.condition}
                  placeholder={placeholders.condition}
                />
                <FormControlGroup
                  name="keywords"
                  label={labels.keywords}
                  placeholder={placeholders.keywords}
                />
                <FormControlGroupSelect
                  options={filterOptions.sortBy}
                  name="sortBy"
                  label={labels.sortBy}
                  placeholder={placeholders.sortBy}
                  selectProps={{
                    defaultValue: filterOptions.sortBy[0].value,
                  }}
                />
                <RangeSlider
                  label={labels.areaRange}
                  range={filterOptions.areaRange}
                  name="areaRange"
                  unit={'m²'}
                />
              </SimpleGrid>
            </Box>
            <Stack>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                gridGap={{ base: 4, xl: 7 }}
                alignItems={'center'}
                w="full"
              >
                <PropertyTypeButtons
                  flex={1}
                  display={{ base: 'none', md: 'block' }}
                  onSubmitHandler={onSubmit}
                />
                <Button
                  mb={1}
                  isLoading={isLoading}
                  type="submit"
                  colorScheme={submitButton.colorScheme}
                  loadingText={submitButton.loadingText}
                  alignSelf={{ base: 'center', md: 'flex-end' }}
                >
                  {submitButton.text}
                </Button>
              </Flex>
              <Box
                h={{ base: 9, md: 6 }}
                pr={{ base: 3, md: 'none' }}
                alignSelf={'flex-end'}
              >
                {Object.values(router.query).length > 0 && (
                  <ClearFilterButton
                    onClick={() => {
                      methods.reset();
                      methods.reset({});
                      methods.handleSubmit(onSubmit)();
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Container>
      </form>
    </FormProvider>
  );
};
