import {
  ContactCtaHeadingSectionProps,
  HeroImageProps,
  ImageFormHeaderProps,
  ImageSectionHeaderProps,
  SectionHeadingProps,
  ServicesBannersSectionProps,
  TextSectionProps,
} from '@brick/ui-components';
import { Box } from '@chakra-ui/react';
import { ctaRoutes, menuRoutes } from '../lib/routes/routes';
import { mapUrlToStrapi } from '../lib/utils/mapUrlToStrapi';

export const servicesBannersSectionContent: ServicesBannersSectionProps = {
  sectionHeading: {
    title: 'SLUŽBA REALITNÝ AGENT PREDÁVAJÚCEHO / KUPUJÚCEHO',
    description:
      'Vaša spokojnosť je u nás v BRICK reality na prvom mieste. Svedčia o tom aj Vaše referencie, ktoré nás posúvajú dopredu. ',
  },
  servicesBanners: {
    banners: [
      {
        image: '/banners/sell-banner.png',
        title: 'CHCEM PREDAŤ / PRENAJAŤ',
        description:
          'Rozmýšľate o predaji nehnuteľnosti? V BRICK reality máte všetky služby na kľúč. Profesionálne, efektívne a transparentne. Od prvej konzultácie až po predaj, alebo prenájom nehnuteľnosti. Ponúkame individuálny prístup,  ktorý si zaslúžite.',
        cta: [
          {
            label: 'Zistite viac',
            href: ctaRoutes.sell.href,
          },
        ],
      },
      {
        image: '/banners/buy-banner.png',
        title: 'CHCEM KÚPIŤ / PRENAJAŤ SI',
        description:
          'Hľadáte bývanie? Nájsť na trhu ideálnu nehnuteľnosť je časovo náročné. V mnohých prípadoch ide o investíciu na celý život. Službou agent kupujúceho Vám BRICK reality poskytne makléra, právnika a finančného odborníka.',
        cta: [
          {
            label: 'Zistite viac',
            href: ctaRoutes.buy.href,
          },
        ],
      },
    ],
  },
};

export const ebookBanner = {
  image: '/banners/mobile.png',
  title: 'SPRIEVODCA SVETOM REALÍT',
  description: 'Zmeníme Váš pohľad na predaj a kúpu nehnuteľnosti!',
  cta: {
    children: 'Stiahnuť e-book',
    href: mapUrlToStrapi(
      '/uploads/sample_b54ca25670.pdf?updated_at=2022-02-10T06:11:47.802Z'
    ),
    external: true,
  },
};

export const imageSectionHeaderContent: ImageSectionHeaderProps = {
  image: '/section-headers/first-phase.png',
  title: 'PRVÁ FÁZA SPOLUPRÁCE',
  description: 'Je lepšie raz vidieť osobne, ako stokrát preklikať',
};

export const sellPageHeroImageContent: HeroImageProps = {
  image: '/hero-images/sell.png',
  titleLines: [
    'MÁTE ZLÚ SKÚSENOSŤ',
    'S NEPROFESIONÁLNYMI REALITKAMI?',
    'NECHAJTE SA PRÍJEMNE PREKVAPIŤ!',
  ],
};

export const buyPageHeroImageContent: HeroImageProps = {
  image: '/hero-images/buy.png',
  titleLines: [
    'REALITNÉ SKLAMANIE?',
    'VŠETKY REALITKY SÚ NA JEDNO KOPYTO?',
    'VYSKÚŠAJTE BRICK REALITY!',
  ],
};

export const careerPageHeroImageContent: HeroImageProps = {
  image: '/hero-images/career.png',
  titleLines: [
    'KEĎ BUDEŠ ROBIŤ TO,',
    'ČO SI VŽDY ROBIL, TAK DOSTANEŠ TO,',
    'ČO SI VŽDY DOSTAL!',
  ],
};

export const homePagePerksSectionHeading: SectionHeadingProps = {
  title: (
    <Box textAlign={'center'}>
      HĽADÁTE KVALITU?
      <Box>SME TU PRE VÁS!</Box>
    </Box>
  ),

  description:
    'Sme realitná kancelária, ktorá láme predsudky voči realitnej činnosti. Vyťažíme z každej situácie maximum a hájime Vaše záujmy.',
  alignItems: 'center',
};

export const contactCtaHeadingSectionContent: ContactCtaHeadingSectionProps = {
  contactCtaHeading: {
    title:
      'Potrebujete poradiť, alebo sa zorienovať? Zavolajte nám. Sme tu pre Vás.',
    link: {
      label: '+421 948 504 607',
      href: 'tel:+421948504607',
      note: 'Po - Pia: 09:30 - 18:30',
    },
  },
};

export const realityTextContent: TextSectionProps = {
  textWithHeading: {
    heading: { title: 'REALITA PRI KÚPE / PRENAJATÍ SI NEHNUTEĽNOSTI' },
    content: `Na Slovensku ide o pomerne novú a málo využívanú službu. Ľudia si myslia, že si vedia všetko zabezpečiť najlepšie sami. Ak si plánujete nájsť bývanie 
    podľa svojich predstáv, prichádzate na realitný trh v určitom čase. To najlepšie Vám môže ujsť pred nosom, z toho ostatného hľadáte ihlu v kope sena. 
    Aby ste si splnili svoj sen, nestačí len video obhliadka, je potrebné vidieť skutočný stav nehnuteľnosti. Koľko nehnuteľností si viete pozrieť, aby ste si 
    vybrali tú pravú pre Vás a ani jedna Vám neušla? Čo ak je cena prestrelená a majiteľ „skúša“ predávať po svojom?  <br>
    Služba agent kupujúceho má svoje opodstatnenie. V zahraničí je bežnou praxou, že ľudia sa zveria do rúk odborníka, ktorý sleduje realitný trh. 
    Preverí skutkový a právny stav tých nehnuteľností, ktoré sú v úzkom výbere a zabezpečí pre svojich klientov bezproblémový realitný obchod. 
  <br>Možno hľadáte tú pravú nehnuteľnosť práve teraz. Už ste natrafili na maklérov, ktorí Vám povedia k ponúkanému bytu, či domu len základné informácie? 
    Stretli ste sa s majiteľom, z ktorého ste mali pocit, že chce niečo zamlčať? Nemáte strach, že budete pri takej veľkej životnej transakcii podvedení?
  <br>Preto Vám v BRICK reality dáme odborníka, ktorý je na Vašej strane a zabezpečí bezproblémový realitný obchod. Za túto profesionálnu službu si 
    pýtame menej, než je štandardom v zahraničí. Zároveň u nás klient platí až keď sa uzavrie kúpna zmluva s majiteľom nehnuteľnosti. 
  <br>V istých prípadoch cena našej služby a vyjednanej nižšej ceny za nehnuteľnosť je pre vás výhodnejšia ako pôvodná kúpna cena.
    `,
  },
};

export const propertyEvaluationSectionHeaderContent: ImageFormHeaderProps = {
  image: '/section-headers/property-evaluation.png',
  title: 'BEZPLATNÉ OCENENIE NEHNUTEĽNOSTI',
  description:
    'Predávate nehnuteľnosť a neviete správne odhadnúť cenu? Pošlite nám dopyt a my sa o všetko postaráme.',
};

export const financesConsultationSectionHeaderContent: ImageFormHeaderProps = {
  image: '/section-headers/finances-consultation.png',
  title: 'PORADENSTVO V OBLASTI VAŠICH FINANCIÍ',
  description:
    'Kupujete nehnuteľnosť a potrebujete hypotekárny úver? Pošlite nám dopyt a zistíme vaše možnosti.',
};

export const sellPageConsultationFormSectionContent = {
  heading: {
    title: 'MÁTE ZÁUJEM O NAŠE SLUŽBY?',
    description:
      'Zvažujete predaj, alebo prenájom nehnuteľnosti? Vyžadujete služby na profesionálnej úrovni, odbornosť a individuálny prístup? Vitajte v BRICK reality. Nadštandardný realitný servis, ktorý Vám ušetrí Váš čas a finančné prostriedky.',
  },
  contactCtaHeading: contactCtaHeadingSectionContent.contactCtaHeading,
};

export const buyPageConsultationFormSectionContent = {
  heading: {
    title: 'MÁTE ZÁUJEM O NAŠE SLUŽBY?',
    description:
      'Nájdeme Vám domov podľa Vašich želaní. Majte na svojej strane odborníka, ktorý sa venuje práve Vám. ',
  },
  contactCtaHeading: contactCtaHeadingSectionContent.contactCtaHeading,
};

export const featuredPropertiesSliderContent = {
  heading: {
    title: 'NAJNOVŠIE NEHNUTEĽNOSTI V PONUKE',
    description:
      'Pre ich zobrazenie pokračujte do sekcie ponuka nehnuteľností.',
  },
  cta: {
    href: menuRoutes.properties.href,
    children: 'Ponuka nehnuteľností',
    buttonProps: { colorScheme: 'black' },
  },
};
