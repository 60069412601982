import { PropertyCardProps } from '@brick/model';
import { menuRoutes } from 'apps/client/lib/routes/routes';
import { GetPropertiesQuery } from 'apps/client/lib/sdk/sdk';
import { getCloudinaryWatermarkUrl } from 'apps/client/lib/utils/getCloudinaryWatermarkUrl';
import { mapUrlToStrapi } from 'apps/client/lib/utils/mapUrlToStrapi';
import { statusWatermarkMap } from '../const/property.const';

export interface MapProjectDataToPropertyCardProps {
  projects: GetPropertiesQuery['projects']['data'];
  coordinates?: boolean;
  gallery?: boolean;
  excludeStatusWatermark?: boolean;
}

export const mapProjectDataToPropertyCard = (
  props: MapProjectDataToPropertyCardProps
) => {
  const { projects, coordinates = false, gallery = false, excludeStatusWatermark = false } = props;

  return projects.map((p) => {
    const project = p.attributes;
    const propertyCard: PropertyCardProps = {
      image: getCloudinaryWatermarkUrl(
        project.featuredImage?.data?.attributes.url,
        excludeStatusWatermark ? undefined :
          statusWatermarkMap[project.status.data?.id]
      ),
      title: project.title,
      items: project.details.map((d) => d.content),
      attributes: {
        area: `${project.area} m²`,
        rooms: project.subcategory?.data?.attributes.name,
        locality: project.city?.data?.attributes.name,
        offer: project.action?.data?.attributes.name,
      },
      price: `${project.advertisedPrice.toLocaleString()} €`,
      cta: {
        label: 'Podrobnosti',
        href: `${menuRoutes.properties.href}/${project.slug}`,
      },
    };

    if (coordinates) {
      propertyCard['coordinates'] = {
        lat: project.gpsY,
        lng: project.gpsX,
      };
    }

    if (gallery) {
      propertyCard['gallery'] = [
        project.featuredImage.data,
        ...project.gallery.data,
      ].map((image) => {
        return mapUrlToStrapi(image?.attributes.url);
      });
    }

    return propertyCard;
  });
};
