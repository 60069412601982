import { Box, BoxProps, HStack } from '@chakra-ui/react';
import React from 'react';
import { MdClear } from 'react-icons/md';

export interface ClearFilterButtonProps extends BoxProps {}

export const ClearFilterButton = (props: ClearFilterButtonProps) => {
  const { ...restOfProps } = props;

  return (
    <HStack
      alignItems={'center'}
      spacing={1}
      cursor={'pointer'}
      _hover={{ color: 'primary.500', transition: 'all ease-in-out 300ms' }}
      fontSize={{ base: 'md' }}
      {...restOfProps}
    >
      <MdClear />
      <Box textTransform={'none'} pb={1}>
        zrušiť filter
      </Box>
    </HStack>
  );
};
