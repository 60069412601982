import {
  GetPropertyFilterOptionsQuery,
  useGetPropertyFilterOptionsQuery,
} from 'apps/client/lib/sdk/sdk';
import { capitalize } from './../../../../../../libs/utils/src/lib/string';
import { getFilterOptionsQuery } from './getFilterOptionsQuery';

const SUBCATEGORIES_BLACKLIST = ['pre rodinné domy'];

const sortByOptions = [
  { text: 'Od najnovších', value: 'createdAt:desc' },
  { text: 'Od najstarších', value: 'createdAt:asc' },
  { text: 'Od najlacnejších', value: 'advertisedPrice:asc' },
  { text: 'Od najdrahších', value: 'advertisedPrice:desc' },
];

const mapPropertyDataToOptions = (
  data: GetPropertyFilterOptionsQuery[
    | 'propertyActions'
    | 'propertyConditions'
    | 'subcategories'
    | 'cities'],
  blacklist = []
) => {
  return (
    data.data
      // @ts-ignore
      .filter((d) => !blacklist.includes(d.attributes.name))
      .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name, 'sk'))
      .map((d) => {
        return {
          text: capitalize(d.attributes.name),
          value: String(d.attributes.realsoftId),
        };
      })
  );
};

export interface UseFilterOptionsProps {
  useOnlyRelevantOptions?: boolean;
  includeOnlyActiveProperties?: boolean;
}

export const useFilterOptions = (props?: UseFilterOptionsProps) => {
  const { data } = useGetPropertyFilterOptionsQuery(
    getFilterOptionsQuery(props)
  );

  if (!data) {
    console.log('WAITING FOR FILTER OPTIONS');
    return;
  }

  return {
    action: mapPropertyDataToOptions(data.propertyActions),
    subcategory: mapPropertyDataToOptions(
      data.subcategories,
      SUBCATEGORIES_BLACKLIST
    ),
    city: mapPropertyDataToOptions(data.cities),
    condition: mapPropertyDataToOptions(data.propertyConditions),
    sortBy: sortByOptions,
    priceRange: [0, 400000] as [number, number],
    areaRange: [0, 1000] as [number, number],
  };
};
